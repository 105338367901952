.c-site-footer {
  flex: none;
  border-top: 1px solid $color-grey-light;
  padding: $base-spacing-unit 0;

}
.c-site-footer__content {
  >:last-child {
    margin-bottom: 0;
  }
}
.c-site-footer__title{

}
