/*================================
=            HEADINGS            =
================================*/


$font-size-h1: 36px !default;
$font-size-h2: 28px !default;
$font-size-h3: 24px !default;
$font-size-h4: 20px !default;
$font-size-h5: 18px !default;
$font-size-h6: 16px !default;
h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-weight(bold);
}

h1 {
  font-weight: 500;
  text-align: center;
  color: $color-brand-secondary;
  @include rem(font-size, 45px);
}

h2 {
  font-weight: 500;
  text-align: center;
  color: $color-brand-secondary;
  @include rem(font-size, 30px);
}

h3 {
  @include rem(font-size, 20px);
  margin-bottom: 100px;
}

h4 {
  @include font-size($font-size-h4);
}

h5 {
  @include font-size($font-size-h5);
}

h6 {
  @include font-size($font-size-h6);
}
