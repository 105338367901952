$adaptive-placeholder: (
  height: 3em,
  margin: 1em,
  border: 1px,
  border-radius: 1px,
  font-size: 1em,
  border-color: $color-borders,
  label-color: #999999,
  active-color: $color-brand-secondary,
  valid-color: $color-brand-primary,
  placeholder-background-color: rgb(15, 39, 74),
  textarea: false
);

.c-contact-form__input {
  @include adaptive-placeholder();
  color: #FFFFFF;
  width: 100%;
}


$textarea-placeholder : (
  height: 8em,
  textarea: true,
);
// Call textarea placeholder
.c-contact-form__textarea {
  @include adaptive-placeholder($textarea-placeholder);
  width: 100%;
  color: #FFFFFF;
}

.c-contact-form__btn{
  background-color: rgb(15, 39, 74);
  padding: 8px 25px;
  color: $color-brand-primary;
  float: right;
  margin-top: 15px;
  border: solid 1px $color-borders;
}

.c-contact-form__messages {
  font-size: 1.4em;
  &.success{
    margin: 80px 0 40px 0;
    color: $color-brand-secondary;
  }
  &.error{
    color: $color-red;
    margin: 80px 0 40px 0;
  }
}

