/*===============================
=            COLOURS            =
===============================*/


// Set up a colour palette which allows us to theme the entire project from one
// location.

// Colour palette.
$color-brand-primary:               #d78254;
$color-brand-primary-highlight:     lighten($color-brand-primary, 20%);
$color-brand-primary-shadow:        darken($color-brand-primary,  5%);
$color-brand-primary-background:    lighten($color-brand-primary, 60%);

$color-brand-secondary:             #dacb62;
// $color-brand-secondary-highlight:   lighten($color-brand-secondary, 5%);
// $color-brand-secondary-shadow:      darken($color-brand-secondary,  5%);

$color-page-background: #001430;
$color-page-text: #f0f0f0;

$color-borders: lighten($color-page-background, 60%);


$color-grey:                        #666;
$color-grey-dark:                   #333;
$color-grey-light:                  #C5C3C3;
$color-red:                         #D45151;

$color-links: $color-brand-primary;
