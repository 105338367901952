/*================================
=            WRAPPERS            =
================================*/

.o-site-container {

}

// Susy grid container
.o-content-container{
  @include container;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding-left: 5%;
  padding-right: 5%;
  height: 50vh;
  width: 90%;
  text-align: center;
  * {
    box-sizing: border-box;
  }
}
.o-content-container--left{
  text-align: left;
}
.o-content-container--contact{
  padding-top: 60px;
}
