/*------------------------------------*\
  #NAV-PRIMARY
\*------------------------------------*/

.c-nav {
  margin: 20px 0 0 0;
  text-align: center;
  @include breakpoint($break-tablet) {
    text-align: left;
    float: right;
    margin: 23px 20px 0 0;
  }
}
.c-nav__items{
  margin: 0;
  @include breakpoint($break-tablet) {

  }
}


.c-nav__item {
  display: inline-block;
  padding-right: $base-spacing-unit;
  padding-left: $base-spacing-unit;
  @include rem(font-size, 20px);
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  &.active a{
    color: $color-brand-secondary;
  }
}
