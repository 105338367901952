/*=================================
=            FUNCTIONS            =
=================================*/



// Convert any px value into its rem equivalent.
@function rem($number) {
  @return $number / $base-font-size * 1rem;
}

// Halve any input and round the result.
@function halve($number) {
  @return round($number/2);
}
