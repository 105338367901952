/*============================
=            PAGE            =
============================*/

/**
 * Page-level styling (e.g. HTML and BODY elements).
 */

html {
  font-size: ($base-font-size / 16px) * 1em;
  line-height: $base-line-height / $base-font-size;
  @include font-family(sans-serif);
  background-color: $color-page-background;
  color: $color-page-text;
  height: 100%;
  font-family: 'Dosis', sans-serif;
  letter-spacing: 1px;
}

body{
  height: 100%;
}

p{
  font-weight: 300;
  @include rem(font-size, 19px);
}
