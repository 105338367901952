.c-home {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
}

.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.c-home{
  -webkit-tap-highlight-color:transparent;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}
