.c-header__logo{
  font-weight: 500;
  @include rem(font-size, 30px);
  text-transform: uppercase;
  margin: 15px 0 0 0;
  color: $color-brand-primary;
  line-height: 1;
  text-align: center;
  @include breakpoint($break-tablet) {
    float: left;
    margin: 15px 0 0 30px;
  }
}
