/*==================================
=            TYPOGRAPHY            =
==================================*/


// Mixins to help generating typographical rules.
// Shorthand onto specific font-stacks.
// Usage: @include font-family(sans-serif);
@mixin font-family($family) {
  @if ($family=="sans-serif") {
    font-family: Lato, sans-serif;
  }
  @elseif ($family=="serif") {
    font-family: Baskerville, Garamond, Palatino, "Palatino Linotype", "Hoefler Text", "Times New Roman", serif;
  }
  @else {
    @warn "#{$family} is not a valid font-family."
  }
}



// Generate a font-size and baseline-compatible line-height.
@mixin font-size($font-size, $line-height: auto) {
  font-size: $font-size;
  font-size: ($font-size / $base-font-size) * 1rem;
  @if $line-height==auto {
    line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
  }
  @else {
    @if (type-of($line-height)==number or $line-height==inherit or $line-height==normal) {
      line-height: $line-height;
    }
    @elseif ($line-height !=none and $line-height !=false) {
      @warn "‘#{$line-height}’ is not a valid value for `line-height`."
    }
  }
}



// Map keywords onto our numbered weights for convenience.
@mixin font-weight($weight) {
  @if ($weight==normal) {
    font-weight: 300;
  }
  @elseif ($weight==bold) {
    font-weight: 500;
  }
  @else {
    @warn "#{$weight} is not a valid font-weight."
  }
}



// Simple truncation mixin to cut off text using an ellipsis after a certain
// width.
//
// .simple-usage {
//   @include truncate();
// }
//
// .detailed-usage {
//   @include truncate(<value>);
// }
@mixin truncate($width: 100%) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
