/*==============================
=            GLOBAL            =
==============================*/


$susy: (
  columns: 12,
  gutters: .5,
  math: fluid,
  container: 800px,
  output: float,
  global-box-sizing: border-box
);

// Common media queries
$break-mobile-land : 32em;        // 512px
$break-tablet      : 40.063em;    // 641px
$break-desktop     : 50em;        // 800px
$break-desktop-mini: 55em;        // 880px
$break-desktop-med : 64.063em;    // 1025px
$break-desktop-wide: 75em;        // 1200px
$break-desktop-xl  : 88em;        // 1408px
$break-retina      : min-resolution 120dpi;

// Base typographical styles and baseline grid.
$base-font-size:    14px;
$base-line-height:  20px;

// Standardise some UI treatments.
$base-radius:       3px;

$base-transition:   0.333333333s;

$base-spacing-unit: $base-line-height;
