/*==============================
=            MIXINS            =
==============================*/



// Mixin to create a declaration whose value is a rem unit. Also provide pixel fallback.
//
// .simple-usage {
//   @include rem(padding, 10px);
// }

@mixin rem($property, $value) {
  #{$property}: $value;
  #{$property}: rem($value);
}



// Mixin to drop micro clearfix into a selector.
//
// .simple-usage {
//   @include clearfix();
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }

@mixin clearfix($important: false) {

  // Determine whether or not to make declarations important.
  @if ($important == false) {
    $important: null;
  } @else {
    $important: !important;
  }

  &:after {
    content: "" $important;
    display: table $important;
    clear: both $important;
  }

}



// Generate `:hover` and `:focus` styles in one go.
@mixin hocus() {
  &:hover,
  &:focus {
    @content;
  }
}
