/*=============================
=            LINKS            =
=============================*/


a {
  color: $color-links;
  text-decoration: none;
  transition: all 150ms ease-out;
  border-bottom: 3px solid rgba(217, 202, 97, 0);
  @include hocus() {
    border-bottom: 3px solid rgba(217, 202, 97, 1);
  }
}
